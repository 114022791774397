<template>
    <div class="close-header-title no-con">
        <section class="booking-section">
            <div class="container-inner">
                <div v-if="messageToShow" :class="{ 'error-message': isError, 'success-message': isSuccess }">
                    {{ messageToShow }}
                </div>
                <div class="row">
                    <div class="col-lg-2 px-2" v-for="(stats, index) in incomeStatistics" :key="stats.id">
                        <b-card :title="stats.title" img-alt="Image" img-top tag="article">
                            <b-carousel id="carousel-1" v-model="slide[index]" :interval="0" controls indicators
                                background="#ababab" img-width="1024" img-height="480"
                                style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart(index)"
                                @sliding-end="onSlideEnd(index)">

                                <b-carousel-slide v-for="(photo, index) in stats.photos" :key="index"
                                    :img-src="photo.path">
                                </b-carousel-slide>
                            </b-carousel>
                            <div class="box-content">
                                <b-card-text>
                                    <strong>Property Type: </strong> {{ stats.property_type }}
                                </b-card-text>
                                <b-card-text class="address-content">
                                    <strong>Address: </strong> {{ stats.city }}, {{ stats.state }},
                                    {{ stats.country }}
                                </b-card-text>
                                <!-- <b-button :href="booking.link" variant="primary">Go somewhere</b-button> -->
                                <div class="d-flex box-footer">
                                    <div class="gap-y-xs">
                                        <a class="card-blue-btn" type="button" data-cy="card-view-btn"
                                            @click="propertyDetail(stats.id)">View
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>
                    <div class="col-lg-4" v-if="incomeStatistics.length === 0">
                        <p>No Properties available.</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import API from '@/api';
import axios from 'axios';
import Vue from 'vue';

import { Loader } from '@googlemaps/js-api-loader';
export default {
    name: 'manage_property',
    components: {
    },
    data() {
        return {
            showIncomeStatistics: false, // Controls conditional rendering of booking cards
            incomeStatistics: [], // Array to store fetched booking data
            slide: [],
            messageToShow: '',
            isError: false,
            isSuccess: false
        };
    },
    mounted() {
        this.getIcomeStatistics();
    },
    methods: {
        onSlideStart(index) {
            this.slide[index] = 0
        },
        onSlideEnd(index) {
            this.slide[index] = this.prperties[index].photos.length - 1
        },
        getIcomeStatistics() {
            API.getIcomeStatistics(fevData => {
                // this.showIncomeStatistics = true;
                console.log(fevData);
                this.incomeStatistics = fevData.data.incomeStatistics;
            });
        },
        propertyDetail(id) {
            this.$router.push({ name: 'property_details', params: { id: id } });
        },
    },
    computed: {
    },
    created() {
    },
}
</script>
<style>
.box-footer .card-blue-btn {
    background-color: #70d3ff;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
    gap: 8px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #f5c6cb;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #c3e6cb;
}
.booking-section .row {
    margin: 0 -0.5rem;
}
.booking-section .card {
    padding: 0px !important;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    border: 1px solid #e7e7e8;
    margin: 0;
    margin-bottom: 24px;
}
.booking-section .card .card-body {
    padding: 0;
}
.booking-section .card .card-body .box-content {
    padding: 12px;
    height: auto;
}
.booking-section .card .card-body .box-content .address-content {
    min-height: 42px;
}
.booking-section .card .card-body .box-content .box-footer {
    display: flex;
    justify-content: space-between;
}
</style>